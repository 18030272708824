
/*
  On page loads
*/
(function($){
  var $navMobile = $('.navigation.toc');

  $navMobile.click(function() {
    $('.ui.sidebar')
      .sidebar('toggle');
  });
})(jQuery);
